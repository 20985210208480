import { useSelector } from 'react-redux';

import { CurrencyExchangeResult, ProviderStoreItem, StoreItem } from '../../../../../../generated';
import { formatCurrency } from '../../../../../../utils';
import { AppState } from '../../../../../../store/root-reducers';

const useGetRetailAmount = () => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const getRetailAmount = (item: ProviderStoreItem, conversion?: CurrencyExchangeResult) =>
    formatCurrency(
      conversion?.toCurrencyEnum !== item.storeItem?.store?.country?.currencyEnum
        ? Number(item.retailAmount) * Number(conversion?.toAmount)
        : item.retailAmount,
      conversion?.toCurrencyEnum || item.storeItem?.store?.country?.currencyEnum,
      currentUser?.cultureInfo,
    );

  const getRetailAmountStoreItem = (storeItem: StoreItem, conversion?: CurrencyExchangeResult) => {
    const amount = formatCurrency(
      storeItem.retailAmount &&
        conversion?.toAmount &&
        conversion?.toCurrencyEnum !== storeItem?.currencyEnum
        ? Number(storeItem.retailAmount) * Number(conversion?.toAmount)
        : storeItem.retailAmount,
      storeItem.retailAmount && conversion?.toAmount
        ? conversion?.toCurrencyEnum || storeItem?.currencyEnum
        : storeItem?.currencyEnum,
      currentUser?.cultureInfo,
    );

    return amount;
  };

  return { getRetailAmount, getRetailAmountStoreItem };
};

export { useGetRetailAmount };
