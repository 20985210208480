import { useCallback, useEffect, useState } from 'react';
import {
  AuthClient,
  CreateCurrencyExchangeInvoiceDetails,
  CurrencyEnum,
  CurrencyExchangeDetails,
  CurrencyExchangeResult,
  Invoice,
  InvoicesClient,
  PaymentProcessorsClient,
} from '../../../generated';
import { errorToastr } from '../../../utils/toastr';

interface IProps {
  fromAmount: number;
  toCurrencyEnum: CurrencyEnum | undefined;
  fromCurrencyEnum: CurrencyEnum;
  invoiceID?: string;
  onSuccess?: (data: any, next?: () => void, createdInvoice?: Invoice) => void;
  onClose?: () => void;
}

const paymentProcessorsClient = new PaymentProcessorsClient(new AuthClient());

export const useCurrencyConverter = ({
  fromAmount,
  toCurrencyEnum,
  fromCurrencyEnum,
  invoiceID,
  onSuccess,
  onClose,
}: IProps) => {
  const initialState = {
    fromCurrencyEnum,
    toCurrencyEnum,
    toAmount: 0,
    fromAmount,
  };

  const [conversion, setConversion] = useState<CurrencyExchangeResult>(
    initialState as CurrencyExchangeResult,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<string | undefined>(invoiceID);
  const invoiceClient = new InvoicesClient(new AuthClient());

  const fetchConversion = useCallback(
    async (amount = fromAmount, toCurrency = toCurrencyEnum, fromCurrency = fromCurrencyEnum) => {
      if (!toCurrencyEnum) {
        setLoading(false);
        return;
      }
      const payload = {
        fromAmount: amount,
        toCurrencyEnum: toCurrency,
        fromCurrencyEnum: fromCurrency,
      } as CurrencyExchangeDetails;
      setLoading(true);
      try {
        const response = await paymentProcessorsClient.currencyExchange(payload);
        setConversion(response);
        setLoading(false);
      } catch (error: any) {
        errorToastr({ description: (error?.message as string) || 'An error occured' });
        setLoading(false);
      }
    },
    [fromAmount, fromCurrencyEnum, toCurrencyEnum],
  );

  const createExchangeInvoice = async (
    newInvoice?: Invoice,
    destinationCurrencyEnum?: CurrencyEnum,
  ) => {
    setCreateLoading(true);
    try {
      const payload = {
        originalInvoiceID: newInvoice?.invoiceID || invoiceId,
        toCurrencyEnum: destinationCurrencyEnum || toCurrencyEnum,
      } as CreateCurrencyExchangeInvoiceDetails;
      const response = await invoiceClient.createCurrencyExchangeInvoice(payload);

      if (onSuccess) {
        onSuccess(response, undefined, newInvoice);
      }
      setCreateLoading(false);
      if (onClose) onClose();
    } catch (error) {
      errorToastr({ description: error as string });
      setCreateLoading(false);
    }
  };

  useEffect(() => {
    fetchConversion();
  }, [fromAmount, toCurrencyEnum, fromCurrencyEnum, fetchConversion]);

  return {
    conversion,
    loadingConversion: loading,
    createLoading,
    createExchangeInvoice,
    fetchConversion,
    setInvoiceId,
  };
};
